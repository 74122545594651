import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

const Navbar = ({ setConnectModal }) => {
   const [menu, setMenu] = useState(false);
   const [connected, setConnected] = useState(false);
   const { account } = useWeb3React();

   useEffect(() => {
      setConnected(account);
   }, [account]);

   return (
      <>
         <div className='navbar'>
            <Link to='/' className='navbar__home'></Link>
            <ul className='navbar__links'>
               <Link to='/tokenize' className='navbar__links--link'>
                  Tokenize
               </Link>
               <Link to='/auction' className='navbar__links--link'>
                  Auction
               </Link>
               <Link to='/mint' className='navbar__links--link'>
                  Minty NFT's
               </Link>
               <Link to='/stake' className='navbar__links--link'>
                  Stake
               </Link>
               <Link to='/dao' className='navbar__links--link'>
                  DAO
               </Link>
               <Link to='/vote' className='navbar__links--link'>
                  Vote
               </Link>
            </ul>
            <div
               className='navbar__wallet'
               onClick={() => setConnectModal(true)}
            >
               {connected ? 'My Wallet' : 'Connect Wallet'}
            </div>
            <div
               className={menu ? 'menu-btn close' : 'menu-btn'}
               onClick={() => setMenu(!menu)}
            >
               <div className='btn-line'></div>
               <div className='btn-line'></div>
               <div className='btn-line'></div>
            </div>
         </div>
         <div className={menu ? 'menu' : 'menu menu-hide'}>
            <ul className='menu__links'>
               <div
                  className='navbar__wallet navbar__wallet--mobile'
                  onClick={() => {
                     setMenu(!menu);
                     setConnectModal(true);
                  }}
               >
                  {connected ? 'My Wallet' : 'Connect Wallet'}
               </div>
               <Link
                  to='/tokenize'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  Tokenize
               </Link>
               <Link
                  to='/auction'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  Auction
               </Link>
               <Link
                  to='/mint'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  Minty NFT's
               </Link>
               <Link
                  to='/stake'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  Stake
               </Link>
               <Link
                  to='/dao'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  DAO
               </Link>
               <Link
                  to='/vote'
                  onClick={() => setMenu(!menu)}
                  className='menu__links--link'
               >
                  Vote
               </Link>
            </ul>
         </div>
      </>
   );
};

export default Navbar;
