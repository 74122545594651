import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Web3ReactProvider } from '@web3-react/core';
import getLibrary from './lib/getLibrary';
// import { ethers } from 'ethers';
// import { connectors } from './components/Injector';

// import AlchAuction from './components/AlchAuction';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
// import WalletModal from './components/ModalTest';
import ConnectModal from './components/ConnectModal';

import Landing from './pages/Landing';
import Tokenize from './pages/Tokenize';
import Auction from './pages/Auction';
import Mint from './pages/Mint';
import Dao from './pages/Dao';
import Stake from './pages/Stake';
import Vote from './pages/Vote';

const App = () => {
   // const [auctionNotification, setAuctionNotification] = useState(true);
   const [connectModal, setConnectModal] = useState(false);

   return (
      <Web3ReactProvider getLibrary={getLibrary}>
         <Router>
            {/* {auctionNotification && (
               <AlchAuction setAuctionNotification={setAuctionNotification} />
            )} */}
            <Navbar setConnectModal={setConnectModal} />
            {connectModal && <ConnectModal setConnectModal={setConnectModal} />}

            <Route path='/' exact component={Landing} />
            <Route path='/tokenize' component={Tokenize} />
            <Route path='/auction' component={Auction} />
            <Route path='/mint' component={Mint} />
            <Route path='/dao' component={Dao} />
            <Route
               path='/stake'
               component={() => <Stake setConnectModal={setConnectModal} />}
            />
            <Route path='/vote' component={Vote} />

            <div className='landing__content'></div>
            <Footer />
         </Router>
      </Web3ReactProvider>
   );
};

export default App;
