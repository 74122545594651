export const ACTIVE_NETWORK = 1;
export const INFURA_ID = '6b5a41304d2443c887a77866d35b249f';
export const ETHERSCAN_KEY = 'RC2K3GMCK38RSTFN5ZS97UTT39WXJ3WWCE';
export const DUTCH_FACTORY = '0x76A8AFd02b61CDFE79eb1d62E9c3a4BDE157212D';
export const PINATA_API_KEY = 'f957939f2b22918a89b9';
export const PINATA_SECRET =
   '7dd4d5fdde247961941e8d8f2fbace35c34aadd4581c2c5ecf1acbdb98dc0f11';
export const MINTY_FACTORY = '0x1C8c9fCf66d3e899A1039Da8c07438c00617f0d5';

// config old
export const NFT_DAO_ADDRESS = '0xD766D04a400AEe8F16361860d9d9190D0BE17425';
export const ALC_ADDRESS = '0x0000A1c00009A619684135B824Ba02f7FbF3A572';
export const STAKINGPOOL_LP = '0x6d46fb5527bDb6Dcb2716f0FE1F0EA0F9ae019fC';
export const STAKINGPOOL_ADDRESS = '0x82E025bce8f4707b7AD406c036E5b8273389612B';
export const UNIV2_STAKING_ADDRESS =
   '0xfb0997696919ba43275f4156f9550b9f68f5edfe';
export const INFURA_NETWORK = 'homestead';

// rinkeby config new
// export const NFT_DAO_ADDRESS = '0xFFABb5e5eB7621aF082B8403689e6622D71A101C';
// export const ALC_ADDRESS = '0x3a3388c9a29741482fef5da1d8a297bdc1d45215';
// export const STAKINGPOOL_ADDRESS = '0x82E025bce8f4707b7AD406c036E5b8273389612B';
// export const STAKINGPOOL_LP = '0x6d46fb5527bDb6Dcb2716f0FE1F0EA0F9ae019fC';
// export const UNIV2_STAKING_ADDRESS =
//    '0xfb0997696919ba43275f4156f9550b9f68f5edfe';
// export const MERKLE_ADDRESS = '0xE23f250670A8151819D0d72710cb2f9D55761Bb3';
// export const INFURA_NETWORK = 'rinkeby';

// mainnet config
//export const GOVERNOR_ADDRESS = "0x7e3d91Df22e9EaF73146a68699F3e728c2B85F13";
//export const TIMELOCK_ADDRESS = "0xe3927272E9421207555F19d501DCF71E29F9b2DE";
//export const NFT_DAO_ADDRESS = "0x5ef046f040944581cb4dd4652bbb3fa9ba120f09";
//export const INFURA_NETWORK = "homestead";

export const AVERAGE_BLOCK_TIME_IN_SECS = 15;
export const PROPOSAL_LENGTH_IN_BLOCKS = 5760;
export const PROPOSAL_LENGTH_IN_SECS =
   AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS;

export const GARDEN_OPTIONS = [
   {
      tokenName: 'ALCH',
      rewardName: 'ETH',
      tokenGardenName: 'ALCH Fee Pool',
      tokenIconPath: 'alchemy_square.png',
      tokenIconPathReward: 'eth.png',
      descriptionTop: 'Deposit ALCH, earn ETH',
      poolAddress: '0x82E025bce8f4707b7AD406c036E5b8273389612B',
      tokenAddress: '0x0000A1c00009A619684135B824Ba02f7FbF3A572',
      open: true
   },
   {
      tokenName: 'ALCH-ETH LP',
      rewardName: 'ALCH',
      tokenGardenName: 'LP Staking Pool',
      tokenIconPath: 'alchemy_square.png',
      tokenIconPathReward: 'alchemy_square.png',
      descriptionTop: 'Deposit ALCH-ETH LP, earn ALCH',
      poolAddress: '0x6d46fb5527bDb6Dcb2716f0FE1F0EA0F9ae019fC',
      tokenAddress: '0xfb0997696919ba43275f4156f9550b9f68f5edfe',
      open: true
   }
];
