import React from 'react';

const Footer = () => {
   return (
      <div className='footer'>
         <div className='footer__extra '></div>
         <div className='footer__icons-container'>
            <a
               target='_blank'
               rel='noreferrer'
               href='mailto:contact@alchemydao.com'
               className='footer__icons-container--icon footer__icons-container--icon-email'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://twitter.com/alchemydao'
               className='footer__icons-container--icon footer__icons-container--icon-twitter'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://discord.com/invite/mMMGBrbqHG'
               className='footer__icons-container--icon footer__icons-container--icon-discord'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://github.com/AlchemyDAO'
               className='footer__icons-container--icon footer__icons-container--icon-github'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://etherscan.io/address/0xdc2778E19C7F32D2Cf0c4c90B705Fb702Aa94150#code'
               className='footer__icons-container--icon footer__icons-container--icon-ethereum'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://docs.alchemydao.com/'
               className='footer__icons-container--icon footer__icons-container--icon-docs'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://alchemydao.substack.com/'
               className='footer__icons-container--icon footer__icons-container--icon-substack'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://t.me/AlchemyOfficial'
               className='footer__icons-container--icon footer__icons-container--icon-telegram'
            ></a>
            <a
               target='_blank'
               rel='noreferrer'
               href='https://forum.alchemydao.com/'
               className='footer__icons-container--icon footer__icons-container--icon-forum'
            ></a>
         </div>
         <div className='footer__alchemy-logo'></div>
      </div>
   );
};

export default Footer;
