import React from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {
   return (
      <>
         <div className='landing'>
            <div className='landing__container--main '>
               <div className='landing__container--secondary card-shadow'>
                  <div className='landing__container--secondary--absolute-wrapper'>
                     <div className='landing__container--secondary-text-container'>
                        <h3 className='landing__container--secondary-header'>
                           create a tokenized DAO for any NFT
                        </h3>
                        <p className='landing__container--secondary-subheader'>
                           Turn your NFTs into liquid, community controlled
                           assets that can be traded, vote and control all your
                           NFTs. <br />
                           <br />
                           Create funds, communities, split collections between
                           friends and all with no coding or technical
                           experience.
                        </p>
                        <Link
                           className='landing__container--secondary-button'
                           to='/tokenize'
                        >
                           CREATE NOW
                        </Link>
                     </div>
                  </div>

                  <div className='landing__container--tertiary--bottom-margin'>
                     <div className='landing__container--tertiary'>
                        <div className='landing__container--tertiary-triangle-inverse landing__container--tertiary-triangle-transform--1'></div>
                        <div className='landing__container--tertiary-triangle-normal landing__container--tertiary-triangle-transform--2'></div>
                        <div className='landing__container--tertiary-triangle-inverse landing__container--tertiary-triangle-transform--3'></div>
                     </div>
                     <div className='landing__container--tertiary'>
                        <div className='landing__container--tertiary-triangle-inverse landing__container--tertiary-triangle-transform--2'></div>
                        <div className='landing__container--tertiary-triangle-normal landing__container--tertiary-triangle-transform--4'></div>
                     </div>
                     <div className='landing__container--tertiary'>
                        <div className='landing__container--tertiary-triangle-inverse landing__container--tertiary-triangle-transform--4'></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Landing;
