import React from 'react';

const Proposal = ({
   proposal,
   proposals,
   proposalStatus,
   proposalDescription,
   setProposal,
   setProposalIndex,
   setFunctionComponent,
   index
}) => {
   return (
      <div
         className='vote__proposal'
         onClick={() => {
            setProposalIndex(index);
            setProposal(proposals[index]);
            setFunctionComponent('PROPOSAL');
         }}
      >
         <h5 className='vote__proposal--label vote__proposal--label-id'>
            # {proposal.id.toNumber()}
         </h5>
         <h5 className='vote__proposal--label vote__proposal--label-description'>
            {proposalDescription}
         </h5>
         <h5 className='vote__proposal--label'>{proposalStatus}</h5>
      </div>
   );
};

export default Proposal;
