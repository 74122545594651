import React, { useState, useEffect } from 'react';

import { isAddress } from '@ethersproject/address';
import {
   ALC_ADDRESS,
   INFURA_ID,
   INFURA_NETWORK,
   STAKINGPOOL_LP,
   UNIV2_STAKING_ADDRESS
} from '../constants';
import UNI_V2 from '../constants/abi/univ2.json';
import { Contract } from '@ethersproject/contracts';
import { parseEther, formatEther } from '@ethersproject/units';
import ERC20 from '../constants/abi/erc20.json';
import POOL_ABI from '../constants/abi/poollp.json';
import useErc20Contract from '../hooks/useErc20Contract';
import { InfuraProvider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { GARDEN_OPTIONS } from '../constants';
// import { useForm } from 'react-hook-form';
import usePoolContract from '../hooks/usePoolContract';
import { BigNumber } from '@ethersproject/bignumber';
import getReceipt from '../lib/getReceipt';
import useToast from '../hooks/useToast';
// import { Input } from '../components/inputs';

const Stake = ({ setConnectModal }) => {
   const [alchToStaked, setAlchToStaked] = useState(0.0);
   const [alchLPToStaked, setAlchLPToStaked] = useState(0.0);
   const [alchDetailsModal, setAlchDetailsModal] = useState(false);
   const [lpDetailsModal, setLpDetailsModal] = useState(false);

   const addToast = useToast();
   // const { register, handleSubmit } = useForm();

   const maxAllowance = BigNumber.from(2).pow(256).sub(1);

   const tokenAddress0 = GARDEN_OPTIONS[0].tokenAddress;
   const poolAddress0 = GARDEN_OPTIONS[0].poolAddress;
   const { tokenAddress, tokenName, poolAddress } = GARDEN_OPTIONS[1];

   const { account, library } = useWeb3React();
   // const account = false;
   // const account = '0xefa755b82218dc287ed37bf6463c144ae6609076';

   const erc20_0 = useErc20Contract(tokenAddress0);
   const erc20 = useErc20Contract(tokenAddress);

   const library_infura = new InfuraProvider(INFURA_NETWORK, INFURA_ID);

   const contract =
      isAddress(STAKINGPOOL_LP) && !!POOL_ABI && !!library_infura
         ? new Contract(STAKINGPOOL_LP, POOL_ABI, library)
         : undefined;

   // 0 = Alch, 1 = LP
   const contract_0 = usePoolContract(poolAddress0);
   const contract_1 = usePoolContract(poolAddress);

   //set constants for later displaying
   const [first_loop, set_first_loop] = useState(0);
   const [poolapy, setpoolapy] = useState(0);
   const [unistaked, setunistaked] = useState(0);
   const [rewardleft, setrewardleft] = useState(0);
   // const [ercBalance0, set_ercBalance0] = useState(0);
   const [rewardEarned0, set_rewardEarned0] = useState(0);
   const [amountStaked0, set_amountStaked0] = useState(0);
   const [hasSetAllowance0, set_hasSetAllowance0] = useState(false);
   // const [ercBalance, set_ercBalance] = useState(0);
   const [rewardEarned, set_rewardEarned] = useState(0);
   const [amountStaked, set_amountStaked] = useState(0);
   const [hasSetAllowance, set_hasSetAllowance] = useState(false);
   const [userbalance, setuserbalance] = useState(0)

   // effect hook for updating data
   useEffect(() => {
      // update the ui elements
      async function updateUIStates() {
         if (tokenName === 'ALCH-ETH LP') {
            const univ2 =
               isAddress(UNIV2_STAKING_ADDRESS) && !!UNI_V2 && !!library
                  ? new Contract(UNIV2_STAKING_ADDRESS, UNI_V2, library)
                  : undefined;

            const reserves = await univ2.getReserves();
            const reservealch = formatEther(reserves[0]);
            const reservesweth = formatEther(reserves[1]);
            const uniprice = reservesweth / reservealch;
            const apy =
               (((13500.0) * uniprice * 365.0) / (reservesweth * 2.0)) *
               100.0;
            setpoolapy(apy);
            console.log(reserves);

            const totalstake = await contract.totalSupply();
            setunistaked(totalstake);

            // const timefinish = await contract.periodFinish();
            // const acttime = Math.floor(new Date().getTime() / 1000);

            // let setime = 0;
            // if (timefinish.toNumber() - acttime > 0) {
            //    setime = timefinish.toNumber() - acttime;
            // }

            // let unix_timestamp = setime;
            // Create a new JavaScript Date object based on the timestamp
            // multiplied by 1000 so that the argument is in milliseconds, not seconds.
            // var date = new Date(unix_timestamp * 1000);
            // // Hours part from the timestamp
            // var hours = date.getHours();
            // // Minutes part from the timestamp
            // var minutes = '0' + date.getMinutes();
            // // Seconds part from the timestamp
            // var seconds = '0' + date.getSeconds();

            const alch_contract =
               isAddress(ALC_ADDRESS) && !!ERC20 && !!library
                  ? new Contract(ALC_ADDRESS, ERC20, library)
                  : undefined;

            const rewardsleft = await alch_contract.balanceOf(STAKINGPOOL_LP);

            setrewardleft(rewardsleft);
         }
         // ALCH
         const allowance0 = await erc20_0.allowance(account, poolAddress0);
         const hasSetAllowance0 = allowance0._hex === '0x00' ? false : true;
         set_hasSetAllowance0(hasSetAllowance0);

         // const balance0 = await erc20_0.balanceOf(account);
         // set_ercBalance0(balance0);
         const earned0 = await contract_0.earned(account);
         set_rewardEarned0(earned0);
         const staked0 = await contract_0.balanceOf(account);
         set_amountStaked0(staked0);

         // LP
         const allowance = await erc20.allowance(account, poolAddress);
         const hasSetAllowance = allowance._hex === '0x00' ? false : true;
         set_hasSetAllowance(hasSetAllowance);
         // const balance = await erc20.balanceOf(account);
         // set_ercBalance(balance);
         const earned = await contract_1.earned(account);
         set_rewardEarned(earned);
         const staked = await contract_1.balanceOf(account);
         set_amountStaked(staked);

         const userb = await erc20.balanceOf(account)
         setuserbalance(userb)
      }

      // fix for updating after wallet login
      if (account && first_loop === 0) {
         set_first_loop(1);
         updateUIStates();
      }

      // schedule every 10 sec refresh
      const timer = setInterval(() => {
         if (account) {
            updateUIStates();
         }
      }, 10000);

      // clearing interval
      return () => clearInterval(timer);
      // eslint-disable-next-line
   }, [
      account,
      erc20,
      contract,
      contract_0,
      contract_1,
      poolAddress,
      poolAddress0
   ]);

   const callExit = async contract => {
      try {
         const { hash } = await contract.exit();
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   const callRewards = async contract => {
      try {
         const { hash } = await contract.getReward();
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   const callApprove = async (erc20, poolAddress, maxAllowance) => {
      try {
         console.log('in')

         const { hash } = await erc20.approve(poolAddress, maxAllowance);
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   const callStake = async (values, contract) => {
      try {
         if (!values) {
            throw new Error('Value must be greater than 0');
         }

         // Probably broke this
         const stakeInWei = parseEther(values);

         const { hash } = await contract.stake(stakeInWei);

         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   function format_friendly(input, decimals) {
      const temp = formatEther(input);
      const words = temp.split('.');

      const slicer = words[1].slice(0, decimals);
      const returner = words[0] + '.' + slicer;

      return returner;
   }

   const handleButtonStyling = coin =>
      coin < 0.01
         ? 'stake__card--button stake__card--button-small stake-button--disabled'
         : 'stake__card--button stake__card--button-small';

   return (
      <div className='page'>
         <div className='page__container'>
            <h3 className='page__header'>STAKING POOLS</h3>
            <div className='page__flex-container'>



               <div className='stake__card card-shadow'>
                  <div className='stake__card--top'>
                     <h4 className='stake__card--title'>$ALCH-ETH LP</h4>
                     <h5 className='stake__card--sub-title'>Earn $ALCH</h5>
                     <div className='stake__card--top-uni'></div>
                  </div>
                  <div className='stake__card--center'>
                     <div className='stake__card--inline-div'>
                        <h4 className='stake__card--label stake__card--label--staked'>
                           LP STAKED: <br />{' '}
                           <span>
                              {(amountStaked &&
                                 format_friendly(amountStaked, 4)) ||
                                 '0.0'}
                           </span>
                        </h4>
                        <h4 className='stake__card--label stake__card--label--APY'>
                           APY: {`${poolapy.toFixed(2)}%`} <br />
                        </h4>
                     </div>
                     <div className='stake__card--inline-div'>
                        <h4 className='stake__card--label'>
                           ALCH EARNED: <br />{' '}
                           <span>
                              {(rewardEarned &&
                                 format_friendly(rewardEarned, 4)) ||
                                 '0.0'}
                           </span>
                        </h4>
                        <div className='stake__card--flex-div'>
                           <button
                              className={handleButtonStyling(rewardEarned)}
                              onClick={() => callRewards(contract_1)}
                           >
                              COLLECT
                           </button>
                           <button
                              className={handleButtonStyling(rewardEarned)}
                              onClick={() => callExit(contract_1)}
                           >
                              COLLECT+
                              <br />
                              UNSTAKE
                           </button>
                        </div>
                     </div>
                     <p className='stake__card--small-label'>
                        Stake UNISWAP LP
                     </p>
                     <p className='stake__card--small-label'>
                        Lp Tokens:   {(userbalance &&
                         format_friendly(userbalance, 4)) ||
                     '0.0'}
                     </p>

                     {!hasSetAllowance ? (
                        <button
                           className='stake__card--button'
                           onClick={() => {
                              if (!account) {
                                 setConnectModal(true);
                                 return;
                              }
                              if (!hasSetAllowance) {
                                 callApprove(erc20, poolAddress, maxAllowance);
                              }
                           }}
                        >
                           {account ? 'ENABLE' : 'CONNECT'}
                        </button>
                     ) : (
                        <div className='stake__card--inline-div'>
                           <input
                              className='form__input'
                              onChange={e => setAlchLPToStaked(e.target.value)}
                              value={alchLPToStaked}
                              id='stakedAmount'
                              name='stakedAmount'
                              type='number'
                              step='0.000000000000000001'
                              required
                           />

                           <button
                              className='stake__card--button'
                              onClick={() => {
                                 callStake(alchLPToStaked, contract_1);
                              }}
                           >
                              STAKE
                           </button>
                        </div>
                     )}
                  </div>
                  <div className='stake__card--details'>
                     <h4
                        className='stake__card--details--button'
                        onClick={() => setLpDetailsModal(!lpDetailsModal)}
                     >
                        Details
                     </h4>
                     {lpDetailsModal && (
                        <>
                           <h5 className='stake__card--details--header'>
                              LP STAKING POOL
                           </h5>
                           <p className='stake__card--details--text'>
                              LP Staked: {format_friendly(unistaked, 4)} <br />
                              APY: {poolapy.toFixed(2)}% <br />
                              Rewards per day: 13500 ALCH <br />
                              Rewards left: {format_friendly(rewardleft, 4)}
                           </p>
                        </>
                     )}
                  </div>
               </div>

               <button
                   className='stake__card--button'
                   onClick={() => {
                      window.open(`https://app.uniswap.org/#/add/v2/0x0000a1c00009a619684135b824ba02f7fbf3a572/ETH`);
                   }}
               >
                  Add Liquidity on Uniswap
               </button>



               <div className='stake__card card-shadow'>
                  <div className='stake__card--top'>
                     <h4 className='stake__card--title'>$ALCH</h4>
                     <h5 className='stake__card--sub-title'>Earn $ETH</h5>
                     <div className='stake__card--top-alch'></div>
                  </div>
                  <div className='stake__card--center'>
                     <h4 className='stake__card--label stake__card--label--staked'>
                        ALCH STAKED: <br />{' '}
                        <span>
                           {(amountStaked0 &&
                               format_friendly(amountStaked0, 4)) ||
                           '0.0'}
                        </span>
                     </h4>
                     <div className='stake__card--inline-div'>
                        <h4 className='stake__card--label'>
                           ETH EARNED: <br />{' '}
                           <span>
                              {(rewardEarned0 &&
                                  format_friendly(rewardEarned0, 8)) ||
                              '0.0'}
                           </span>
                        </h4>
                        <div className='stake__card--flex-div'>
                           <button
                               className={handleButtonStyling(rewardEarned0)}
                               onClick={() => callRewards(contract_0)}
                           >
                              COLLECT
                           </button>
                           <button
                               className={handleButtonStyling(rewardEarned0)}
                               onClick={() => callExit(contract_0)}
                           >
                              COLLECT+
                              <br />
                              UNSTAKE
                           </button>
                        </div>
                     </div>
                     <p className='stake__card--small-label'>Stake Alch</p>
                     {!hasSetAllowance0 ? (
                         <button
                             className='stake__card--button'
                             onClick={() => {
                                if (!account) {
                                   setConnectModal(true);
                                   return;
                                }
                                if (!hasSetAllowance0) {

                                   callApprove(
                                       erc20_0,
                                       poolAddress0,
                                       maxAllowance
                                   );
                                }
                             }}
                         >
                            {account ? 'ENABLE' : 'CONNECT'}
                         </button>
                     ) : (
                         <div className='stake__card--inline-div'>
                            <input
                                className='form__input'
                                onChange={e => setAlchToStaked(e.target.value)}
                                value={alchToStaked}
                                id='stakedAmount'
                                name='stakedAmount'
                                type='number'
                                step='0.000000000000000001'
                                required
                            />

                            <button
                                className='stake__card--button'
                                onClick={() => {
                                   callStake(alchToStaked, contract_0);
                                }}
                            >
                               STAKE
                            </button>
                         </div>
                     )}
                  </div>
                  <div
                      className='stake__card--details'
                      onClick={() => setAlchDetailsModal(!alchDetailsModal)}
                  >
                     <h4 className='stake__card--details--button'>Details</h4>
                     {alchDetailsModal && (
                         <>
                            <h5 className='stake__card--details--header'>
                               ALCH FEE POOL
                            </h5>
                            <p className='stake__card--details--text'>
                               The ALCH Pool is fueled by buyouts and single NFT
                               buy inside the DAOs generated by Alchemy, as well
                               as from Auctions and Minty sales. A fixed fee is
                               taken and distributed amongst the $ALCH Stakers.
                               More info{' '}
                               <a href='https://docs.alchemydao.com/protocol/earn-staking-rewards'>
                                  Here
                               </a>
                               .
                            </p>
                         </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Stake;
