import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useWeb3React } from '@web3-react/core';
import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { InfuraProvider } from '@ethersproject/providers';
import { formatEther, parseEther } from '@ethersproject/units';
import { Carousel } from 'react-responsive-carousel';

import { addToast } from '../hooks/useToast';
import getReceipt from '../lib/getReceipt';
import { DUTCH_FACTORY } from '../constants';
import ALCHEMY from '../constants/abi/nftdao.json';
import ABI from '../constants/abi/dutch.json';
import FACTORY_ABI from '../constants/abi/dutchFactory.json';
import ERC_20_ABI from '../constants/abi/erc20.json';
import ERC_721_ABI from '../constants/abi/erc721.json';
import { INFURA_ID, INFURA_NETWORK } from '../constants';
import { Line } from 'rc-progress';

const Auction = () => {
   const { account, library } = useWeb3React();
   // alch address '0x526001cde753b6a8dbe6646a5cae58629b65f420'
   const library_infura = new InfuraProvider(INFURA_NETWORK, INFURA_ID);

   //new for details
   const [auctionAddress, setauctionAddress] = useState('');

   const [ischecked, setischecked] = useState(false);

   // contract variables
   // const [bought, setbought] = useState(0);
   const [tokennname, settokenname] = useState('NAME');
   const [tokensymbol, settokensymbol] = useState('SYMBOL');
   const [tokenProgress, setTokenProgress] = useState('');
   const [commited, setcommited] = useState(0);
   const [finish, setfinish] = useState(0);
   // const [hasclaimed, sethasclaimed] = useState(false);
   // const [minprice, setminprice] = useState(0);
   const [open, setopen] = useState(BigNumber.from(0));
   const [owner, setowner] = useState(
      '0x0000000000000000000000000000000000000000'
   );
   const [price, setprice] = useState(0);
   const [pricevar, setpricevar] = useState(0);
   const [reserve, setreserve] = useState(0);
   const [start, setstart] = useState(0);
   // const [startprice, setstartprice] = useState(0);
   const [tokensleft, settokensleft] = useState(0);
   // const [tokensstart, settokensstart] = useState(0);
   // const [whitelistonly, setwhitelistonly] = useState(false);
   // const [whitelisted, setwhitelisted] = useState([]);
   const [contractbalance, setcontractbalance] = useState('0');

   // contract inputs

   const [amount, setamount] = useState('0');
   const [whitelistdup, setwhitelistdup] = useState('');

   const [min, setmin] = useState(0);
   const [_start, set_start] = useState(0);
   const [_finish, set_finish] = useState(0);
   const [_starttime, set_starttime] = useState(0);
   const [whitelist, setwhitelist] = useState('');
   const [whitelistonlystart, setwhitelistonlystart] = useState(false);

   const [funding, setfunding] = useState(0);

   const [nftArray, setNftArray] = useState([]);
   const [showmedia, setshowmedia] = useState(false);

   const [currenttime, setcurrenttime] = useState(new Date().getTime());
   const [token, settoken] = useState('');
   const [createToken, setCreateToken] = useState('');

   const [createMode, setCreateMode] = useState(false);

   const location = useLocation();
   const history = useHistory();

   // Start from url parameter
   useEffect(() => {
      const address = location.search.split('?address=')[1];
      if (address) {
         setauctionAddress(address);
         checkDetails(address);
      }
      // eslint-disable-next-line
   }, [location]);

   function handleChangeFund(event) {
      const values = event.target.value;
      setfunding(values);
   }

   // function handleChangeAuctionAddress(event) {
   //    const values = event.target.value;
   //    setauctionAddress(values);
   // }

   function handleChangeAmount(event) {
      const values = event.target.value;
      setamount(values);
   }

   function handleChangeWhitelistUp(event) {
      const values = event.target.value;
      setwhitelistdup(values);
   }

   function handleChangeMin(event) {
      const values = event.target.value;
      setmin(values);
   }

   function handleChangeStart(event) {
      const values = event.target.value;
      set_start(values);
   }

   function handleChangeFinish(event) {
      const values = event.target.value;
      set_finish(values);
   }

   function handleChangeStartTime(event) {
      const values = event.target.value;
      set_starttime(values);
   }

   function handleChangeWhitelist(event) {
      const values = event.target.value;
      setwhitelist(values);
   }

   function handleChangeWhitelistOnly(event) {
      const values = event.target.checked;
      setwhitelistonlystart(values);
   }

   // effect hook for updating data
   useEffect(() => {
      // update the ui elements
      async function updatePriceCheck() {
         console.log('updating');
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library_infura
               ? new Contract(auctionAddress, ABI, library_infura)
               : undefined;
         try {
            const temp_price = await dutch_contract.getCurrentPrice();
            setprice(temp_price);
         } catch (e) {
            console.log(e);
         }
      }

      // schedule every 15 sec refresh
      const timer = setInterval(() => {
         updatePriceCheck();
      }, 15000);

      // clearing interval
      return () => clearInterval(timer);
      // eslint-disable-next-line
   }, [account, library, auctionAddress]);

   // effect hook for updating data
   useEffect(() => {
      // schedule every 15 sec refresh
      const timer = setInterval(() => {
         const dater = new Date().getTime();
         if (start * 1000 > dater) {
            console.log(dater);
            setcurrenttime(start * 1000 - dater);
         } else {
            setcurrenttime(0);
         }
      }, 1000);

      // clearing interval
      return () => clearInterval(timer);
   }, [start]);

   async function checkDetails(address) {
      const { hide: hidePending } = addToast({
         body: 'Getting auction details...',
         type: 'loading',
         hideAfter: 0
      });

      const queryAddress = address ? address : auctionAddress;

      const dutch_contract =
         isAddress(queryAddress) && !!ABI && !!library_infura
            ? new Contract(queryAddress, ABI, library_infura)
            : undefined;

      // console.log(await dutch_contract.bought());
      try {
         setshowmedia(false);
         // const temp_bought = await dutch_contract.bought();
         // const temp_hasclaimed = await dutch_contract.hasClaimed();
         // const temp_minprice = await dutch_contract.minPrice();
         // const temp_startprice = await dutch_contract.startPrice();
         const temp_finish = await dutch_contract.finish();
         const temp_open = await dutch_contract.open();
         const temp_owner = await dutch_contract.owner();
         const temp_pricevar = await dutch_contract.price();
         const temp_start = await dutch_contract.start();
         const temp_token = await dutch_contract.token();
         const temp_tokensleft = await dutch_contract.tokensLeft();
         const temp_tokensstart = await dutch_contract.tokensStart();
         // const temp_whitelistonly = await dutch_contract.whitelistOnly();

         console.log(temp_start.toNumber() * 1000);

         const erc20_contract =
            isAddress(temp_token) && !!ERC_20_ABI && !!library_infura
               ? new Contract(temp_token, ERC_20_ABI, library_infura)
               : undefined;
         const temp_symbol = await erc20_contract.symbol();
         const temp_name = await erc20_contract.name();
         const balance = await erc20_contract.balanceOf(queryAddress);

         // setbought(temp_bought);
         setfinish(temp_finish);
         // sethasclaimed(temp_hasclaimed);
         // setminprice(temp_minprice);
         setopen(temp_open);
         setowner(temp_owner);
         // setowner('0x526001cde753b6a8dbe6646a5cae58629b65f420');

         setpricevar(temp_pricevar);
         setstart(temp_start);
         // setstartprice(temp_startprice);
         settoken(temp_token);
         // settokensstart(temp_tokensstart);
         settokensleft(temp_tokensleft);
         // setwhitelistonly(temp_whitelistonly);
         setcontractbalance(balance);

         settokenname(temp_name);
         settokensymbol(temp_symbol);
         setTokenProgress(
            ((temp_tokensstart - temp_tokensleft) / temp_tokensstart) * 100
         );

         try {
            const temp_price = await dutch_contract.getCurrentPrice();
            setprice(temp_price);
         } catch (e) {
            console.log(e);
         }

         if (account) {
            const temp_commited = await dutch_contract.committed(account);
            const temp_reserve = await dutch_contract.reserve(account);
            // const temp_whitelisted = await dutch_contract.whitelisted(account);

            // setwhitelisted(temp_whitelisted);
            setreserve(temp_reserve);
            setcommited(temp_commited);
         }

         try {
            const alchemy_contract =
               isAddress(temp_token) && !!ALCHEMY && !!library_infura
                  ? new Contract(temp_token, ALCHEMY, library_infura)
                  : undefined;

            // const gov = await alchemy_contract._governor();
            // const tim = await alchemy_contract._timelock();
            const nftarraycount = await alchemy_contract._nftCount();

            let i = 0;
            let temp_array = [];
            let nft_temp_select = [];

            for (i; i < nftarraycount; i++) {
               let temp_array_member = await alchemy_contract._raisedNftArray(
                  i
               );
               temp_array.push(temp_array_member);

               // get details of nft
               let erc721_contract =
                  isAddress(temp_array_member.nftaddress) &&
                  !!ERC_721_ABI &&
                  !!library_infura
                     ? new Contract(
                          temp_array_member.nftaddress,
                          ERC_721_ABI,
                          library_infura
                       )
                     : undefined;

               console.log(erc721_contract);

               let tname = '';
               let tsymbol = '';

               try {
                  tname = await erc721_contract.name();
               } catch {}
               try {
                  tsymbol = await erc721_contract.symbol();
               } catch {}

               let turl;
               try {
                  turl = await erc721_contract.tokenURI(
                     temp_array_member.tokenid
                  );
               } catch {}

               console.log(turl);

               let res;
               let json;

               try {
                  res = await fetch(turl);
                  json = await res.json();
                  setshowmedia(true);
               } catch {
                  setshowmedia(false);
               }

               console.log(json);
               let imager = undefined;

               try {
                  imager = json.image;
               } catch {}

               const ipfsPrefix = 'https://dweb.link/ipfs/';

               if (imager && imager.startsWith('ipfs:')) {
                  console.log(json.image);
                  imager =
                     'https://gateway.pinata.cloud/' + imager.substring(7);
               } else {
                  try {
                     imager = json.result.data.big_image;
                  } catch {}
               }

               if (imager && !imager.startsWith('http')) {
                  imager = ipfsPrefix + imager;
               }

               console.log(imager);

               let typer = 'image';
               try {
                  let response = await fetch(imager);
                  let data = await response.blob();
                  console.log(data.type);
                  if (data.type.includes('video')) {
                     typer = 'video';
                  } else {
                     typer = 'image';
                  }
               } catch (e) {}

               console.log(temp_array_member.tokenid);

               let temp_json = {
                  address: temp_array_member.nftaddress,
                  tokenid: temp_array_member.tokenid,
                  price: temp_array_member.price,
                  arraynumber: i,
                  sale: temp_array_member.forSale,
                  name: tname,
                  symbol: tsymbol,
                  url: turl,
                  image: imager,
                  type: typer
               };
               nft_temp_select.push(temp_json);
            }

            setNftArray(nft_temp_select);
            console.log(nft_temp_select);
         } catch (e) {}

         setischecked(true);

         hidePending();
         addToast({ body: 'Loading successful', type: 'success' });
      } catch (e) {
         console.log(e);
         hidePending();
         addToast({
            body: 'There was a problem checking the contracts values',
            type: 'error'
         });
         setischecked(false);
      }
   }

   async function bid() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         const token_amount = parseEther(amount);
         const temp_price = price;
         const trans_obj = {
            value: token_amount.mul(temp_price).div('1000000000000000000')
         };

         const { hash } = await dutch_contract.bid(token_amount, trans_obj);
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function claim() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         const { hash } = await dutch_contract.claim();
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function closeAuction() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         const { hash } = await dutch_contract.closeAuction();
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function fundAuction() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const token_contract =
            isAddress(token) && !!ERC_20_ABI && !!library
               ? new Contract(token, ERC_20_ABI, library.getSigner(account))
               : undefined;
         const sendamount = parseEther(funding);

         const { hash } = await token_contract.transferFrom(
            account,
            auctionAddress,
            sendamount
         );
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function withdraw() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         const { hash } = await dutch_contract.withdraw();
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function updateWhitelist() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         let temparray = [];

         let whitelistarray = whitelistdup.split(',');

         let i;
         for (i = 0; i < whitelistarray.length; i++) {
            temparray.push(whitelistarray[i]);
         }

         if (temparray[0] === '') {
            temparray = [];
         }

         console.log(temparray);

         const { hash } = await dutch_contract.updateWhitelist(temparray);
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   async function startAuction() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutch_contract =
            isAddress(auctionAddress) && !!ABI && !!library
               ? new Contract(auctionAddress, ABI, library.getSigner(account))
               : undefined;

         console.log(min);
         console.log(_start);
         console.log(_finish);
         console.log(_starttime);
         console.log(whitelistonlystart);

         let ts = _finish;
         let unix_seconds = new Date(ts).getTime() / 1000;

         let tsstart = _starttime;
         let unix_seconds_start = new Date(tsstart).getTime() / 1000;
         let temparray = [];

         let whitelistarray = whitelist.split(',');

         let i;
         for (i = 0; i < whitelistarray.length; i++) {
            temparray.push(whitelistarray[i]);
         }

         if (temparray[0] === '') {
            temparray = [];
         }

         console.log(temparray);

         const { hash } = await dutch_contract.startAuction(
            parseEther(min),
            parseEther(_start),
            unix_seconds_start,
            unix_seconds,
            temparray,
            whitelistonlystart
         );
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
      checkDetails();
   }

   //format addresses in ui
   function format_address(address) {
      const new_address = address.substring(0, 10) + '...' + address.slice(-8);
      return new_address;
   }

   //format addresses in ui
   function get_date(dater) {
      const redate = new Date(dater.toNumber() * 1000);
      return redate.toLocaleString();
   }

   function copylink() {
      const el = document.createElement('textarea');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      el.setAttribute('readonly', '');
      const minty_uri = 'https://alchemydao.com/auction';
      const stringer = minty_uri + '?address=' + auctionAddress;
      el.value = stringer;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      addToast({ body: 'Copied Link To Your Clipboard', type: 'success' });
   }

   function calc_display_amount(inputamount) {
      let show_price = BigNumber.from(0);
      try {
         let myinput = parseFloat(inputamount);
         let temp_price = price;
         show_price = BigNumber.from(myinput * 1000000)
            .mul(temp_price)
            .div(1000000);
      } catch (e) {
         console.log(e);
      }

      return show_price.toString();
   }

   function formatcurrenttime(n) {
      n = n / 1000;

      var days = Math.floor(parseInt(n / (24 * 3600)));

      n = n % (24 * 3600);
      var hours = Math.floor(parseInt(n / 3600));

      n %= 3600;
      var minutes = Math.floor(n / 60);

      n %= 60;
      var seconds = Math.floor(n);

      let stringer = '';
      if (days > 0) {
         stringer = stringer + days.toString() + ' Days ';
      }
      if (hours > 0) {
         stringer = stringer + hours.toString() + ' Hours ';
      }
      if (minutes > 0) {
         stringer = stringer + minutes.toString() + ' Minutes ';
      }
      if (seconds > 0) {
         stringer = stringer + seconds.toString() + ' Seconds ';
      }

      return stringer;
   }

   async function createAuction() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      try {
         const dutchfactory_contract =
            isAddress(DUTCH_FACTORY) && !!FACTORY_ABI && !!library
               ? new Contract(
                    DUTCH_FACTORY,
                    FACTORY_ABI,
                    library.getSigner(account)
                 )
               : undefined;
         console.log(createToken);

         dutchfactory_contract.once(
            'NewDutchAuctionContract',
            (deployed, event) => {
               console.log('Contract event');
               console.log(deployed);
               history.push(`/auction?address=${deployed}`);
            }
         );

         const { hash } = await dutchfactory_contract.DutchAuctionMint(
            createToken,
            account
         );
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   return (
      <div className='page'>
         <div className='page__container'>
            <h3 className='page__header'>AUCTION</h3>
            <div className='page__flex-container card-shadow'>
               <div className='auction__container'>
                  {ischecked && (
                     <h5
                        className='auction__container--button'
                        onClick={() => {
                           setischecked(false);
                        }}
                     >
                        Go back
                     </h5>
                  )}
                  <div className='auction__container--inline-div'>
                     <h4 className='auction__container--header'>
                        {!createMode
                           ? 'Enter Auction Address'
                           : 'Create Auction'}
                     </h4>
                     <div
                        className='auction__container--buttons-container'
                        onClick={() => setCreateMode(!createMode)}
                     >
                        <div
                           onClick={() => setCreateMode(!createMode)}
                           className={
                              !createMode
                                 ? 'auction__container--buttons-container--secondary move-right'
                                 : 'auction__container--buttons-container--secondary'
                           }
                        ></div>
                        <h4
                           className={
                              !createMode
                                 ? 'auction__container--label auction__container--label-market'
                                 : 'auction__container--label auction__container--label-market color-gray'
                           }
                        >
                           FIND
                        </h4>
                        <h4
                           className={
                              !createMode
                                 ? 'auction__container--label color-gray'
                                 : 'auction__container--label'
                           }
                        >
                           CREATE
                        </h4>
                     </div>
                  </div>

                  {!createMode && !ischecked && (
                     <div className='auction__form'>
                        <h3 className='form__label'>Auction Address:</h3>
                        <input
                           type='text'
                           required
                           className='form__input auction__form--input'
                           onChange={e => setauctionAddress(e.target.value)}
                           value={auctionAddress}
                        />
                        <button
                           className='form__button auction__form--button'
                           onClick={() => checkDetails()}
                        >
                           GET AUCTION DETAILS
                        </button>
                     </div>
                  )}
                  {createMode && (
                     <div className='auction__form'>
                        <h3 className='form__label'>
                           Enter Token Address to be auctioned:
                        </h3>
                        <input
                           type='text'
                           required
                           className='form__input auction__form--input'
                           onChange={e => setCreateToken(e.target.value)}
                           value={createToken}
                        />
                        <button
                           className='form__button auction__form--button'
                           onClick={createAuction}
                        >
                           CREATE AUCTION
                        </button>
                     </div>
                  )}
                  {!createMode && ischecked && open.toNumber() !== 0 ? (
                     <div className='auction__form'>
                        {currenttime > 0 && (
                           <h3 className='form__label'>
                              Auction starts in:
                              {formatcurrenttime(currenttime)}
                           </h3>
                        )}

                        <button
                           className='dao__details-container--button vote__button--vote dao__details-container--button-copy width-auto'
                           type='button'
                           onClick={() => copylink()}
                        >
                           Copy Link
                        </button>

                        <h3 className='form__label'>
                           {tokennname} ({tokensymbol})
                        </h3>
                        <div className='dao__details-container--flex-container width-100'>
                           <h3 className='form__label'>Auction Start</h3>
                           <h3 className='form__label'>{get_date(start)}</h3>
                        </div>
                        <div className='dao__details-container--flex-container width-100'>
                           <h3 className='form__label'>Auction End</h3>
                           <h3 className='form__label'>{get_date(finish)}</h3>
                        </div>

                        <div className='auction__media-container'>
                           {showmedia === true ? (
                              <Carousel>
                                 {nftArray.map((data, i) => (
                                    <div>
                                       {data.image ? (
                                          data.type === 'image' ? (
                                             <img src={data.image} alt='NFT' />
                                          ) : (
                                             <video
                                                autoPlay
                                                loop
                                                muted
                                                inline
                                                controls
                                                className='w-full'
                                             >
                                                <source
                                                   src={data.image}
                                                   type='video/mp4'
                                                />
                                                Your browser does not support
                                                the video tag.
                                             </video>
                                          )
                                       ) : (
                                          <img
                                             src='alchemy_square.png'
                                             alt='alchemy logo'
                                          />
                                       )}
                                       <p className='legend'>
                                          {data.name} ({data.symbol})
                                       </p>
                                    </div>
                                 ))}
                              </Carousel>
                           ) : (
                              <img
                                 src='alchemy_square.png'
                                 alt='Alchemy Logo'
                              />
                           )}
                        </div>

                        <h3 className='form__label'>Token Address:</h3>
                        <a
                           href={'https://etherscan.io/token/' + token}
                           target='_blank'
                           rel='noreferrer'
                        >
                           <h3 className='form__label'>
                              {format_address(token)}
                           </h3>
                        </a>
                        <div className='mint__line-container centered'>
                           <h3 className='form__label'>Auction Progress:</h3>
                           <Line
                              percent={[tokenProgress || 0, 100]}
                              strokeWidth='4'
                              trailWidth='4'
                              strokeColor={[
                                 '#08c4d1',
                                 {
                                    '100%': '#ffffff',
                                    '0%': '#08c4d1'
                                 }
                              ]}
                              strokeLinecap='round'
                           />
                           <h3 className='form__label margin-top-8'>
                              {formatEther(tokensleft)} {tokensymbol} left
                           </h3>
                        </div>

                        <h3 className='form__label'>Current Price:</h3>

                        {open.toNumber() !== 2 ? (
                           <h3 className='form__label'>
                              {formatEther(price)} ETH
                           </h3>
                        ) : (
                           <h3 className='form__label'>
                              {formatEther(pricevar)} ETH
                           </h3>
                        )}
                        {account && owner === account && (
                           <h3 className='form__label'>Owner Functions:</h3>
                        )}

                        {account &&
                        owner === account &&
                        open.toNumber() === 0 &&
                        formatEther(contractbalance) !== '0.0' ? (
                           <>
                              <h3 className='form__label'>Start Auction:</h3>

                              <h3 className='form__label'>
                                 Min Price (in ETH):
                              </h3>
                              <input
                                 required
                                 className='form__input auction__form--input'
                                 type='text'
                                 onChange={e => handleChangeMin(e)}
                              />
                              <h3 className='form__label'>
                                 Start Price (in ETH):
                              </h3>
                              <input
                                 className='form__input auction__form--input'
                                 type='text'
                                 onChange={e => handleChangeStart(e)}
                              />
                              <h3 className='form__label'>Start</h3>
                              <input
                                 className='form__input auction__form--input'
                                 type='datetime-local'
                                 onChange={e => handleChangeStartTime(e)}
                              />
                              <h3 className='form__label'>Finish</h3>
                              <input
                                 className='form__input auction__form--input'
                                 type='datetime-local'
                                 onChange={e => handleChangeFinish(e)}
                              />
                              <h3 className='form__label'>Whitelist Only</h3>
                              <input
                                 required
                                 className='form__input auction__form--input'
                                 type='checkbox'
                                 onChange={e => handleChangeWhitelistOnly(e)}
                              />

                              {whitelistonlystart === true ? (
                                 <>
                                    <h3 className='form__label'>
                                       Whitelist (comma separated)
                                    </h3>
                                    <input
                                       className='form__input auction__form--input'
                                       type='text'
                                       onChange={e => handleChangeWhitelist(e)}
                                    />
                                 </>
                              ) : (
                                 ''
                              )}

                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={startAuction}
                              >
                                 Start Auction
                              </button>
                           </>
                        ) : (
                           ''
                        )}

                        {account &&
                        owner === account &&
                        open.toNumber() === 0 &&
                        formatEther(contractbalance) === '0.0' ? (
                           <>
                              <h3 className='form__label'>
                                 Fund your Auction Contract
                              </h3>
                              <h3 className='form__label'>
                                 Amount of {tokennname}({tokensymbol}) to send:
                              </h3>
                              <input
                                 required
                                 className='form__input auction__form--input'
                                 type='text'
                                 onChange={e => handleChangeFund(e)}
                              />

                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={fundAuction}
                              >
                                 Send {tokennname} to Auction
                              </button>
                           </>
                        ) : (
                           ''
                        )}

                        {account &&
                        owner === account &&
                        open.toNumber() === 1 ? (
                           <>
                              <h3 className='form__label'>Close Auction</h3>

                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={closeAuction}
                              >
                                 Close Auction
                              </button>
                           </>
                        ) : (
                           ''
                        )}

                        {account &&
                        owner === account &&
                        open.toNumber() === 2 ? (
                           <>
                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={withdraw}
                              >
                                 Withdraw
                              </button>
                           </>
                        ) : (
                           ''
                        )}

                        {account && owner === account ? (
                           <>
                              <h3 className='form__label'>
                                 New Whitelist (Comma separated)
                              </h3>
                              <input
                                 required
                                 className='form__input auction__form--input'
                                 type='text'
                                 onChange={e => handleChangeWhitelistUp(e)}
                              />

                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={updateWhitelist}
                              >
                                 Update Whitelist
                              </button>
                           </>
                        ) : (
                           ''
                        )}

                        {open.toNumber() === 1 && currenttime === 0 ? (
                           <div className='w-full'>
                              <h3 className='form__label'>Bid</h3>
                              {commited !== 0 ? (
                                 <>
                                    <h3 className='form__label'>
                                       You committed: {formatEther(commited)}{' '}
                                       ETH
                                    </h3>
                                    <h3 className='form__label'>
                                       You reserved: {formatEther(reserve)}{' '}
                                       Tokens
                                    </h3>
                                 </>
                              ) : (
                                 ''
                              )}
                              <h3 className='form__label'>
                                 Amount of tokens to bid for (your bid amount
                                 will be calculated automatically according to
                                 the latest price)
                              </h3>
                              <input
                                 required
                                 className='form__input auction__form--input'
                                 type='number'
                                 min='0'
                                 placeholder='0'
                                 onChange={e => handleChangeAmount(e)}
                              />
                              <h3 className='form__label'>
                                 You pay:{' '}
                                 {formatEther(calc_display_amount(amount))} ETH
                              </h3>
                              <button
                                 className='form__button vote__button--vote  auction__form--button'
                                 type='button'
                                 onClick={bid}
                              >
                                 Bid
                              </button>
                           </div>
                        ) : (
                           ''
                        )}
                        {open.toNumber() === 2 && (
                           <button
                              className='form__button vote__button--vote  auction__form--button'
                              type='button'
                              onClick={claim}
                           >
                              CLAIM
                           </button>
                        )}
                     </div>
                  ) : (
                     ''
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Auction;
