import ABI from "../constants/abi/nftdao_factory.json";
import useContract from "./useContract";
import {NFT_DAO_ADDRESS} from "../constants";

export default function useNftDaoFactoryContract() {

    //return useContract("0x130D90307F1E133cDCD1E36dFbA415C11F8DC994",ABI)

    return useContract(NFT_DAO_ADDRESS,ABI)
}
